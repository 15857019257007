import { initialize } from './helpers/init'
import loadBundles from './helpers/loadBundles'
import iframeResizer from './helpers/iframeResizer'

/* Optional */
import svg4everybody from 'svg4everybody'
import 'focus-visible'

/* Import components as needed */
import Menu from './menu'
import Card from './components/card'
import Cta from './components/cta'
import Lightbox from './components/lightbox'
import Tabs from './components/tabs'
import Accordion from './components/accordion'
import InstagramShare from './components/instagramShare'

document.body.classList.remove('no-js')
iframeResizer()

const initPageTransitions = () => {
  if (
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype
  ) {
    import('./helpers/pageTransitions').then((obj) => {
      const revealOnEnter = obj.default
      revealOnEnter()
    })
  } else {
    document.body.classList.add('no-observer')
  }
}

initPageTransitions()

svg4everybody({
  polyfill: true,
})

document.onreadystatechange = () => {
  if (document.readyState === 'complete') {
    document.body.classList.remove('is-loading')
    document.body.classList.add('is-loaded-complete')
  }
}

/* Initialize components as needed */
initialize('menu', Menu)
initialize('tabs', Tabs)
initialize('accordion', Accordion)
initialize('card', Card)
initialize('cta', Cta)
initialize('lightbox', Lightbox)
initialize('instagram-share', InstagramShare)


/*
  ** Conditionally load bundles **
  ---
  Only do this if you have large components or dependencies that you don't want to load on every page (e.g. a Vue application). Otherwise import and initialise them in the normal way, above. This example uses the accordion, but you probably don't want to do it this way for this component.
*/

loadBundles()

/* Only load Vue if #app element present on the page */
const importVue = () => {
  if (!document.querySelector('#app')) return

  import('./vue/app').then((object) => {
    const vueApp = object.default
    vueApp()
  })
}

importVue()
